<template>
  <sign-page
    title-text="转正预警"
    :request="request"
    :title-menus="[]"
    :column-list="columnList"
    :table-actions="[]"
    table-size="large"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  dateOperating
} from '@/fmlib'

import {
  agreementRequest as request
} from '@/api'

export default {
  computed: {
    columnList: {
      get () {
        let data = [{
          title: '姓名',
          field: 'workerName',
          sort: true,
          width: 100
        },
        // {
        //   title: '科室',
        //   field: 'orgName',
        //   sort: true
        // },
        {
          title: '工号',
          field: 'workerCode',
          sort: true
        },
        {
          title: '预警内容',
          field: 'warningText',
          sort: true
        },
        {
          title: '证件（合同）编号',
          field: 'code',
          sort: true
        },
        {
          title: '试用期结束时间',
          field: 'tryEndDate',
          filterRange: true,
          sort: true,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.tryEndDate ? rowData.tryEndDate.slice(0, 10) : '-')
          }
        },
        {
          title: '剩余天数',
          field: 'lastDays',
          filterRange: true,
          width: 130,
          sort: true,
          dataType: Number,
          render: (h, rowData) => {
            return h('div', {
              domProps: {
                innerHTML: rowData.lastDays || '-'
              },
              style: {
                color: rowData.lastDays < 31 ? '#ed4014' : '#ff9900'
              }
            })
          }
        }]
        return data
      }
    }
  },
  data () {
    return {
      request: {
        get: async () => {
          let maxTryEndDate = dateOperating.computeDay({days: 30, date: new Date(), format: 'yy-mm-dd'})
          let datas = await request.getTurn({
            maxTryEndDate
          })
          datas.forEach((v) => {
            let ms = new Date(v.tryEndDate).getTime() - new Date().getTime()
            v.lastDays = parseInt(ms / 1000 / 60 / 60 / 24) + 1
            v.warningText = v.lastDays > 0 ? '剩余' + v.lastDays + '天转正' : '已过试用期结束时间'
          })
          return datas
        }
      }
    }
  }
}
</script>
